<template>
  <div v-if="specialist">
    <div class="page-segment">
      <div class="page-segment-content">
        <Heading :text="$translations.profile.service.edit" :action="action" />
      </div>
    </div>
    <div class="page-segment">
      <div class="page-segment-content">
        <div class="is-enabled-content">
          <div
            class="heading-small"
            v-text="$translations.profile['is-enabled'].title"
          />
          <ToggleField :value="isEnabled" @changed="onChanged" />
        </div>
        <div class="body" v-text="getTextToggleDetail" />
      </div>
    </div>

    <!-- Availability slot size -->
    <div class="page-segment">
      <div class="page-segment-content">
        <div class="is-enabled-content availability-slot-size">
          <div
            class="heading-small"
            v-text="$translations.profile.availability.title"
          />
          <Dropdown
            v-if="availabilitySlotSizeOption.length !== 0"
            :placeholder="$translations.profile.availability.title"
            :options="availabilitySlotSizeOption"
            :value="availabilitySlotSize"
            :platform="$store.getters.platform"
            @value="changeTimeSlots"
          />
        </div>
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <Availability :services="specialist.services" />
        <CalendarConnection />
      </div>
    </div>

    <div class="page-segment" v-if="address && address.address">
      <div class="page-segment-content address">
        <div class="header margin-bottom">
          <div
            class="heading-small header-text header-title"
            v-text="address.title"
          />
          <OpenInGoogleMaps
            v-if="(address && address.place_id) || address.address"
            class="header-link"
            :language="$store.getters.language.lang"
            :place_id="address.place_id"
            :full-address="address.address"
          />
        </div>

        <div class="address-container">
          <span
            class="icon-display text-gray-60 icon-consultory-directory margin-right-sm"
          />
          <span class="body-small" v-text="address.address" />
        </div>
      </div>
    </div>

    <!-- timezone setting -->
    <div class="page-segment">
      <div class="page-segment-content">
        <LocalizationSettingsSection
          class="content-title"
          :router="$router"
          :lang="$store.getters.language.lang"
          origin="SPECIALIST"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Specialists,
  ToggleField,
  Heading,
  LocalizationSettingsSection,
  OpenInGoogleMaps,
  Dropdown
} from '@seliaco/red-panda'
import Availability from '@/views/profile/components/schedule/Availability'
import CalendarConnection from '@/views/profile/components/schedule/CalendarConnection'

export default {
  name: 'ScheduleSection',
  components: {
    Heading,
    Availability,
    CalendarConnection,
    ToggleField,
    LocalizationSettingsSection,
    OpenInGoogleMaps,
    Dropdown
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      address: null,
      action: {
        icon: 'icon-arrow-big-outline-right',
        callback: () =>
          this.$router.push({
            name: 'ServicesList',
            query: { back: this.$route.fullPath }
          })
      },
      isEnabled: null,
      availabilitySlotSize: null,
      availabilitySlotSizeOption: []
    }
  },
  methods: {
    mount () {
      this.isEnabled = this.specialist?.is_public
      this.availabilitySlotSize =
        this.specialist?.user.calendar?.availability_slot_size?.toString()
      this.getAddress()
      this.getAvailabilitySlotOptions()
    },
    async onChanged (value) {
      const body = {
        is_public: value
      }
      const msg = value
        ? this.$translations.profile['is-enabled'].enabled
        : this.$translations.profile['is-enabled'].disabled

      Specialists.updateSpecialist(body).then(() => {
        this.$emit('update-specialist')
        this.isEnabled = value
        this.$toast({
          text: msg,
          severity: 'success'
        })
      })
    },
    async changeTimeSlots (value) {
      Specialists.updateAvailabilitySlot({
        slot_size: value
      }).then(() => {
        this.availabilitySlotSize = value.toString()
        this.$toast({
          text: this.$translations.profile.availability.message.replace(
            '{size}',
            value
          ),
          severity: 'success'
        })
      })
    },
    getAddress () {
      Specialists.getSpecialistAddress(
        this.$store.getters['auth/specialist'].id
      ).then((response) => {
        this.address = response
      })
    },
    getAvailabilitySlotOptions () {
      Specialists.getAvailabilitySlotOption().then((value) => {
        this.availabilitySlotSizeOption = value
      })
    }
  },
  computed: {
    getTextToggleDetail () {
      return this.isEnabled
        ? this.$translations.profile['is-enabled'].enabled
        : this.$translations.profile['is-enabled'].disabled
    }
  },
  watch: {
    specialist: {
      handler () {
        this.mount()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.address
  &-container
    display: flex
    align-items: center
.header
  display: flex
  justify-content: space-between
.is-enabled-content
  display: flex
  justify-content: space-between
  margin-bottom: 16px
::v-deep
  .btn-link
    font-size: var(--tiny) !important
    @media (min-width: 840px)
      font-size: var(--base) !important

.availability-slot-size
  display: grid
  grid-template-columns: 1fr
  gap: 8px
</style>
