<template>
  <div class="padding-all ">
    ResourceField
  </div>
</template>

<script>
export default {
  name: 'ResourceField'
}
</script>
