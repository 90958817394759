<template>
  <PageContainer
    :title="$translations.profile.title"
    :show-left-button="true"
    :back="closeButton"
  >
    <template v-slot:content>
      <!-- skeleton -->
      <template v-if="loading">
        <div class="page-segment">
          <div class="page-segment-content">
            <div class="profile-skeleton">
              <Skeleton
                class="margin-bottom"
                width="104px"
                height="104px"
                borderRadius="9999px"
              />
              <Skeleton
                class="margin-bottom-xl"
                width="167px"
                height="46px"
                borderRadius="30px"
              />
              <div class="profile-skeleton-row">
                <Skeleton
                  v-for="index in 3"
                  :key="index"
                  width="104px"
                  height="106px"
                  border-radius="16px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-bottom"
              v-for="index in 3"
              :key="index"
              height="106px"
              width="100%"
              border-radius="16px"
            />
          </div>
        </div>
      </template>

      <template v-if="specialist && !loading">
        <!-- header -->
        <div class="page-segment">
          <div class="page-segment-content">
            <ProfileHeader :specialist="specialist" />
          </div>
        </div>

        <!-- sections -->
        <template v-for="(item, index) of options">
          <component
            :key="index"
            :is="item.component"
            v-if="item.show"
            v-bind="item.props"
            @update-specialist="getSpecialist"
          />
        </template>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import { Specialists, PageContainer, Skeleton } from '@seliaco/red-panda'

import ProfileHeader from '@/views/profile/components/Header'
import GeneralSection from '@/views/profile/sections/GeneralSection'
import ScheduleSection from '@/views/profile/sections/ScheduleSection'
import ReviewsSection from '@/views/profile/sections/ReviewsSection'
import ResourcesSection from '@/views/profile/sections/ResourcesSection'
import AccountSection from '@/views/profile/sections/AccountSection'

export default {
  name: 'Profile',
  components: {
    PageContainer,
    ProfileHeader,
    GeneralSection,
    ScheduleSection,
    ReviewsSection,
    ResourcesSection,
    AccountSection,
    Skeleton
  },
  data () {
    return {
      specialist: null,
      loading: true,
      options: [],
      closeButton: {
        callback: () => this.$router.push({ name: 'Home' })
      }
    }
  },
  mounted () {
    if (this.$store.getters['auth/specialist']) {
      this.getSpecialist()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/specialist'],
        () => {
          this.getSpecialist()
        }
      )
    }
  },
  methods: {
    getSpecialist () {
      this.loading = true
      Specialists.getBySpecialistId(this.$store.getters['auth/specialist'].id)
        .then((response) => {
          this.specialist = response
          this.loading = false
        })
        .finally(() => {
          this.getComponents()
        })
    },
    getComponents () {
      this.options = [
        {
          component: GeneralSection,
          props: {
            specialist: this.specialist
          },
          show: this.$route.query.section === 'general'
        },
        {
          component: ScheduleSection,
          props: {
            specialist: this.specialist
          },
          show: this.$route.query.section === 'schedule'
        },
        {
          component: ReviewsSection,
          show: this.$route.query.section === 'reviews'
        },
        {
          component: ResourcesSection,
          show: this.$route.query.section === 'resources'
        },
        {
          component: AccountSection,
          show: this.$route.query.section === 'account'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler () {
        this.getComponents()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-skeleton
  display: flex
  flex-direction: column
  align-items: center
  &-row
    display: flex
    flex-wrap: wrap
    gap: 10px
</style>
