<template>
  <div class="container">
    <div v-for="(tab, index) in tabs"
      class="section-box"
      :class="section === tab.section && 'section-box-active'"
      :key="index"
      @click="navigate(tab.section)">
      <div class="icon-display section-box-icon" :class="iconClasses(tab.icon, tab.section)" />
      <div
        class="heading-title"
        :class="section === tab.section && 'text-purple'"
        v-text="tab.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileTab',
  props: {
    tabs: Array
  },
  data () {
    return {
      section: ''
    }
  },
  mounted () {
    if (!this.$route.query.section) {
      this.navigate(this.tabs[0].section)
    } else {
      const tab = this.tabs.filter(o => o.section === this.$route.query.section)
      this.section = tab[0].section
    }
  },
  methods: {
    navigate (section) {
      this.section = section
      this.$router.replace(
        {
          query: {
            ...this.$route.query, section
          }
        }
      )
    },
    iconClasses (icon, section) {
      const isActive = section === this.section
      const background = isActive ? 'bg-purple-20' : 'bg-white'
      const color = isActive ? 'text-purple' : 'text-black-100'
      return `${color} ${background} ${icon}`
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  display: flex
  padding: 16px 0
  width: 100%
  gap: 16px
  justify-content: center
  .section-box
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 10px
    height: 106px
    min-width: 104px
    border-radius: 16px
    cursor: pointer
    background-color: var(--gray-5)
    border: 1px solid var(--purple-20)
    transition: .2s ease-in-out
    &-active
      background-color: var(--purple-5)
      border: 1px solid var(--purple-50)
      box-shadow: var(--shadow-purple)
    &-icon
      transition: .2s ease-in-out
      border-radius: var(--border-full)
      padding: 10px
</style>
