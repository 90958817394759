export const Genders = (translations) => {
  return [
    {
      value: 'FEMALE',
      label: translations.gender.FEMALE
    },
    {
      value: 'MALE',
      label: translations.gender.MALE
    },
    {
      value: 'NON_BINARY',
      label: translations.gender.NON_BINARY
    },
    {
      value: 'NO_SPECIFY',
      label: translations.gender.NO_SPECIFY
    }
  ]
}
