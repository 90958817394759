<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <AccountForm />
    </div>
  </div>
</template>

<script>
import AccountForm from '@/views/profile/components/account/AccountForm'

export default {
  name: 'AccountSection',
  components: {
    AccountForm
  }
}
</script>
