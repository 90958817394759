<template>
  <div v-if="form">
    <!-- name -->
    <div class="specialist-name">
      <InputTextField
        id="first_name"
        v-model="form.first_name"
        :label="$translations.form['first-name']"
        :disabled="true"
      />
      <InputTextField
        id="last_name"
        v-model="form.last_name"
        :label="$translations.form['last-name']"
        :disabled="true"
      />
    </div>
    <!-- gender -->
    <DropdownField
      class="margin-top"
      :options="options.genders"
      v-model="form.gender"
      :label="$translations.form.gender"
      :mobile-options="true"
      :disabled="true"
    />
    <!-- birthday -->
    <div class="margin-top">
      <div class="body-small text-gray-50" v-text="$translations.form.birthday" />
      <DateField
        id="birthday"
        v-model="form.birthday"
        :label="$translations.form['birthday']"
        :placeholder-day="$translations.form['date-placeholder'].day"
        :placeholder-month="$translations.form['date-placeholder'].month"
        :placeholder-year="$translations.form['date-placeholder'].year"
        :mobile-options="true"
        :lang="$store.getters.language.lang"
        :disabled="true"
      />
    </div>
    <!-- phone -->
    <div class="margin-top">
      <div class="body-small text-gray-50" v-text="$translations.form.phone" />
      <PhoneField
        id="phone_number"
        v-model="phoneValue"
        :mobile-options="true"
        :label="$translations.form.phone"
        :placeholder-phone="$translations.form.phone"
        :disabled="true"
        :disabled-country="true"
      />
    </div>

    <!-- email -->
    <InputTextField
      id="email"
      class="margin-top"
      v-model="form.email"
      :label="$translations.form.email"
      :disabled="true"
    />
  </div>
</template>

<script>
import {
  InputTextField,
  DropdownField,
  DateField,
  PhoneField
} from '@seliaco/sea-otter'
import { Genders } from '@/constants/genders'
export default {
  name: 'PersonalInfo',
  components: {
    InputTextField,
    DropdownField,
    DateField,
    PhoneField
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      form: null,
      phoneValue: null,
      options: {
        genders: []
      }
    }
  },
  mounted () {
    this.setForm()
  },
  methods: {
    setForm () {
      this.options.genders = Genders(this.$translations)
      this.form = {
        first_name: this.specialist.user.first_name,
        last_name: this.specialist.user.last_name,
        birthday: this.specialist.user.birthday
          ? this.$moment(this.specialist.user.birthday)
            .tz(this.$store.getters.userTimezone)
            .toDate() : null,
        gender: this.specialist.user.gender || '',
        address: this.specialist.user.address || null,
        dial_code: this.specialist.user.dial_code?.replace('+', '') || null,
        phone: this.specialist.user.phone || null,
        email: this.specialist.user.email
      }

      this.phoneValue = {
        phone: this.form.phone,
        dialCode: this.form.dial_code,
        country: this.specialist.user.dial_country_code
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.specialist-name
  display: flex
  gap: 10px
</style>
