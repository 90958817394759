<template>
  <div class="reviews">
    <div
      class="heading margin-bottom"
      v-text="$translations.profile.tabs.reviews"
    />
    <ReviewSelector :selection="selection" @changed="get" />

    <!-- loader -->
    <template v-if="loading">
      <div v-for="i in 3" :key="i">
        <div class="flex gap-1 margin-bottom">
          <Skeleton width="20px" height="20px" borderRadius="16px" />
          <Skeleton width="90px" height="20px" borderRadius="16px" />
        </div>
        <div class="margin-bottom">
          <Skeleton width="100%" height="60px" borderRadius="16px" />
        </div>
        <hr class="margin-y" />
      </div>
    </template>

    <template v-else-if="reviews && reviews.length > 0">
      <div class="reviews-item" v-for="(review, index) in reviews" :key="index">
        <!-- date -->
        <div class="reviews-item_title margin-bottom-xs">
          <div class="icon-recommend-on text-purple heading-display" />
          <div
            class="content-title"
            v-text="
              $moment(review.created_at).tz(timeZone).format('MMM, DD, YYYY')
            "
          />
        </div>

        <!-- content -->
        <div class="reviews-item_text body-big" v-text="review.content" />

        <hr class="margin-y" />
      </div>
    </template>

    <DataZeroSimple :text="$translations['data-zero'].reviews" v-else />
  </div>
</template>

<script>
import { Reviews } from '@seliaco/red-panda'
import ReviewSelector from '@/views/profile/components/reviews/ReviewSelector'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import Skeleton from 'primevue/skeleton'
import { mapGetters } from 'vuex'

export default {
  name: 'Reviews',
  components: { DataZeroSimple, ReviewSelector, Skeleton },
  data () {
    return {
      reviews: null,
      loading: false,
      selection: null
    }
  },
  created () {
    if (this.specialist) {
      this.get()
    } else {
      this.$store.watch(
        () => this.specialist,
        () => {
          this.get()
        }
      )
    }
  },
  methods: {
    get (code = 'RECOMMEND') {
      this.loading = true
      this.selection = code

      Reviews.read({
        columns: 'recommended, content, created_at',
        eq: {
          specialist: this.specialist.id,
          recommended: code === 'RECOMMEND',
          reference_type: 'SPECIALIST'
        }
      })
        .then(async (response) => {
          this.reviews = response.data
            .filter((review) => review.content)
            .sort((a, b) =>
              this.$moment(b.created_at).diff(this.$moment(a.created_at))
            )
        })
        .finally(() => (this.loading = false))
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'userTimezone',
      specialist: 'auth/specialist'
    })
  }
}
</script>
<style lang="sass" scoped>
.reviews
  &-item
    display: flex
    flex-direction: column
    &_title
      display: flex
      align-items: center
      gap: 6px
      margin-bottom: 6px
</style>
