<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <Reviews />
    </div>
  </div>
</template>

<script>
import Reviews from '@/views/profile/components/reviews/Reviews'

export default {
  name: 'ReviewsSection',
  components: {
    Reviews
  }
}
</script>
