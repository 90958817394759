<template>
  <div class="grid gap-2 grid-cols-2 margin-bottom">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="selection === option.code ? 'bg-purple-10 shadow-purple' : ''"
      class="border border-purple-20 rounded-xl padding-all cursor-pointer"
      @click="onSelectOption(option.code)"
    >
      <img class="m-auto w-7 h-7 margin-bottom-xs" :src="option.image" alt="" />
      <div
        class="w-full heading-tag text-center"
        :class="selection === option.code ? 'text-purple' : 'text-black-80'"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewSelector',
  props: {
    selection: String
  },
  data () {
    return {
      options: [
        {
          code: 'RECOMMEND',
          text: this.$translations.profile.recommend,
          image: require('@/assets/placeholderimages/recommend.svg')
        },
        {
          code: 'IMPROVE',
          text: this.$translations.profile.improve,
          image: require('@/assets/placeholderimages/improve.svg')
        }
      ]
    }
  },
  methods: {
    onSelectOption (code) {
      this.$emit('changed', code)
    }
  }
}
</script>
