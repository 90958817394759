<template>
  <div class="padding-all ">
    AccountForm
  </div>
</template>

<script>
export default {
  name: 'AccountForm'
}
</script>
