<template>
  <div>
    <!-- avatar -->
    <img class="profile-pic" :src="avatar" alt="" />
    <UploadFileField
      class="margin-y"
      :label="$translations.form['update-picture']"
      @upload-file="handleUploadImage"
    />
    <ProfileTab :tabs="tabs" />
  </div>
</template>

<script>
import ProfileTab from '@/components/navigation/ProfileTab'
import { UploadFileField } from '@seliaco/sea-otter'
import { Bucket, Specialists } from '@seliaco/red-panda'

export default {
  name: 'ProfileHeader',
  components: {
    ProfileTab,
    UploadFileField
  },
  data () {
    return {
      uploadedImage: null,
      tabs: [
        {
          text: this.$translations.profile.tabs.general,
          icon: 'icon-person-off',
          section: 'general'
        },
        {
          text: this.$translations.profile.tabs.schedule,
          icon: 'icon-clock-off',
          section: 'schedule'
        },
        {
          text: this.$translations.profile.tabs.reviews,
          icon: 'icon-recommend-off',
          section: 'reviews'
        }
        // {
        //   text: this.$translations.profile.tabs.resources,
        //   icon: 'icon-briefcase-off',
        //   section: 'resources'
        // },
        // {
        //   text: this.$translations.profile.tabs.account,
        //   icon: 'icon-information-off',
        //   section: 'account'
        // }
      ]
    }
  },
  props: {
    specialist: Object
  },
  methods: {
    handleUploadImage (file) {
      const img = new Image()
      var _URL = window.URL || window.webkitURL
      var objectUrl = _URL.createObjectURL(file)
      img.onload = () => {
        const { width, height } = img

        if (
          width &&
          height &&
          width >= 400 &&
          height >= 400 &&
          width === height
        ) {
          Bucket.uploadImage(file)
            .then((result) => {
              this.handleUpdate(result.url)
            })
            .catch(() => {
              this.$toast({
                text: this.$translations.error.default,
                severity: 'error'
              })
            })
        }
        if (width !== height) {
          this.$toast({
            text: this.$translations.error['specialist-pic-square'],
            severity: 'error'
          })
        }

        if (width < 400 && height < 400) {
          const msg = this.$translations.error['specialist-pic'].replace(
            '{{size}}',
            `${width}x${height}`
          )

          this.$toast({
            text: msg,
            severity: 'error'
          })
        }

        _URL.revokeObjectURL(objectUrl)
      }

      img.onerror = () => {
        this.$toast({
          text: this.$translations.error['specialist-pic-failed-load'],
          severity: 'error'
        })
      }
      img.src = objectUrl
    },
    handleUpdate (image) {
      this.uploadedImage = image
      const body = {
        url: image
      }
      Specialists.updateSpecialistPicture(this.specialist.id, body).then(() => {
        this.$toast({
          severity: 'success',
          text: this.$translations.success.updated
        })

        this.$store.commit('auth/SET_USER', {
          ...this.$store.getters['auth/user'],
          profile_picture: image
        })
      })
    }
  },
  computed: {
    avatar () {
      return (
        this.uploadedImage ||
        this.specialist.user.profile_picture ||
        require('@/assets/images/avatar.png')
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-pic
  width: 104px!important
  height: 104px!important
  object-fit: cover
  border-radius: 9999px
  margin: 0 auto
</style>
