<template>
  <div class="margin-y">
    <div
      class="content-title margin-bottom-sm"
      v-text="$translations.profile['time-in-advance']"
    />
    <div class="body" v-text="getTime" />
  </div>
</template>

<script>
export default {
  name: 'TimeInAdvance',
  props: {
    time: Number
  },
  computed: {
    getTime () {
      const time = `${this.time} ${
        this.time < 2
          ? this.$translations.locale.hour
          : this.$translations.locale.hours
      }`

      return this.$translations.profile[
        'time-in-advance-description'
      ].replace('{time}', time)
    }
  }
}
</script>
