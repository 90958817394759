<template>
  <ModalContainer
    @on-nav-back="$emit('close')"
    @right-action-clicked="submit"
    :rightAction="{ icon: 'icon-check-single' }"
    :title="$translations.profile.languages">
    <template v-slot:content>
      <!-- Selected languages -->
      <div class="heading-small margin-bottom">
        {{ $translations.profile['my-languages'] }}
      </div>

      <div class="flex flex-row flex-wrap gap-1" v-if="languagesData && languagesData.length > 0">
        <div class="flex gap-1 padding-y-xs padding-left  padding-right-xs  bg-purple-10 rounded-2xl" v-for="(item, index) in languagesData" :key="index">
          <img class="language-flag flex-none self-center" :src="flag(item.code)" alt="" />

          <div class="flex-grow content-title self-center">
            {{ item.name }}
          </div>

          <div class="flex-none self-center heading icon-close cursor-pointer" @click="remove(item)"></div>
        </div>
      </div>

      <div class="body-small text-purple text-center" v-else>
        {{ $translations.form['select-one-more-options'] }}
      </div>

      <hr class="margin-y border-gray-10" />

      <!-- languages selection -->
      <MultiselectCheckbox
        :loading="loading"
        :options="languagesOptions"
        :value="languagesData"
      />

    </template>
  </ModalContainer>
</template>

<script>
import { Specialists } from '@seliaco/red-panda'
import ModalContainer from '@/components/modals/ModalContainer'
import MultiselectCheckbox from '@/components/fields/MultiselectCheckbox'

export default {
  name: 'LanguagesFormModal',
  components: { MultiselectCheckbox, ModalContainer },
  props: {
    languages: []
  },
  data () {
    return {
      loading: false,
      languagesOptions: [
        {
          name: this.$translations.languages.ENGLISH,
          code: 'ENGLISH'
        },
        {
          name: this.$translations.languages.SPANISH,
          code: 'SPANISH'
        },
        {
          name: this.$translations.languages.FRENCH,
          code: 'FRENCH'
        },
        {
          name: this.$translations.languages.PORTUGUESE,
          code: 'PORTUGUESE'
        },
        {
          name: this.$translations.languages.ITALIAN,
          code: 'ITALIAN'
        }
      ],
      languagesData: this.languages
    }
  },
  methods: {
    remove (option) {
      const index = this.languagesData.findIndex(o => o.code === option.code)

      this.languagesData.splice(index, 1)
    },
    submit () {
      this.$emit('changed', this.languagesData)

      const body = {
        specialist: {
          languages: this.languagesData.map((o) => o.code)
        }
      }

      Specialists.update(
        body,
        {
          id: this.$store.getters['auth/specialist'].id
        }
      ).then(() => {
        this.$toast({
          severity: 'success',
          text: this.$translations.success.updated
        })
      }).catch(() => {
        this.$toast({
          severity: 'error',
          text: this.$translations.error.default
        })
      })
    },
    flag (lang) {
      const languageCodes = this.$languageCodes.filter(o => o.name === lang)

      return require(`@/assets/countries/flags/${languageCodes[0].flag}.svg`)
    }
  }
}
</script>
