<template>
  <div>
    <!-- title && action button -->
    <div class="profile-title margin-bottom-xs">
      <LabelAction
        :label="$translations.profile['security-information']"
        :action="$translations.general.edit"
        @clicked="$router.push({ name: 'ModifyPassword' })"
      />
    </div>

    <!-- form -->
    <div>
      <InputEmailField
        v-model="email"
        :label="$translations.form.email"
        :disabled="true"
      />
      <!-- password -->
      <div class="p-field margin-top">
        <PasswordField
          id="password"
          :label="$translations.form['password-placeholder']"
          v-model="form.password"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PasswordField, InputEmailField } from '@seliaco/sea-otter'
import LabelAction from '@/components/LabelAction'

export default {
  name: 'ProfileSecurityForm',
  components: {
    PasswordField,
    InputEmailField,
    LabelAction
  },
  props: {
    email: String
  },
  data () {
    return {
      loading: false,
      form: {
        password: '            '
      },
      enableForm: false
    }
  },
  methods: {
    submit () {
      this.loading = true

      this.$auth
        .onUpdatePassword(this.form.password)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })

          this.loading = false
          this.enableForm = false
        })
        .catch((error) => {
          console.error('Update password error:', error)

          let errorMessage = this.$translations.error.default

          if (error.code === 'auth/requires-recent-login') {
            errorMessage = this.$translations.error[error.code]

            this.$auth.signOut()
            this.$router.push({
              name: 'SignIn'
            })
          }

          this.$toast({
            severity: 'error',
            text: errorMessage
          })
          this.loading = false
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-title
  display: grid
  grid-template-columns: 1fr min-content
  .button
    height: auto
</style>
