import { render, staticRenderFns } from "./ProfileTab.vue?vue&type=template&id=0569f152&scoped=true&"
import script from "./ProfileTab.vue?vue&type=script&lang=js&"
export * from "./ProfileTab.vue?vue&type=script&lang=js&"
import style0 from "./ProfileTab.vue?vue&type=style&index=0&id=0569f152&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0569f152",
  null
  
)

export default component.exports