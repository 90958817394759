<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <ResourceField />
      <ResourceItem />
    </div>
  </div>
</template>

<script>
import ResourceField from '@/views/profile/components/resources/ResourceField'
import ResourceItem from '@/views/profile/components/resources/ResourceItem'

export default {
  name: 'ResourcesSection',
  components: {
    ResourceField,
    ResourceItem
  }
}
</script>
