<template>
  <div>
    <LabelAction
      :label="$translations.profile.languages"
      :action="$translations.general.edit"
      @clicked="showForm = true"
    />

    <div class="flag-chip-container">
      <div class="flag-item-content" v-for="(item, index) in languagesData" :key="index">
        <img class="language-flag" :src="flag(item.code)" alt="" />
        <div class="content-title" v-text="item.name" />
      </div>
    </div>

    <LanguagesFormModal
      :languages="languagesData"
      @changed="updateLanguages"
      @close="showForm = false"
      v-if="showForm" />
  </div>
</template>

<script>
import LabelAction from '@/components/LabelAction'
import LanguagesFormModal from '@/views/profile/components/general/modals/LanguagesFormModal'

export default {
  name: 'Language',
  components: { LanguagesFormModal, LabelAction },
  props: {
    languages: Array
  },
  data () {
    return {
      showForm: false,
      languagesData: []
    }
  },
  mounted () {
    this.languagesData = this.languages.map((o) => {
      return {
        name: this.$translations.languages[o],
        code: o
      }
    })
  },
  methods: {
    updateLanguages (values) {
      this.languagesData = values
      this.showForm = false
    },
    flag (lang) {
      const languageCodes = this.$languageCodes.filter(o => o.name === lang)

      return require(`@/assets/countries/flags/${languageCodes[0].flag}.svg`)
    }
  }
}
</script>

<style lang="sass">
.flag-chip-container
  display: flex
  flex-wrap: wrap
  gap: 8px
  .flag-item-content
    display: flex
    gap: 6px
    background-color: var(--purple-10)
    padding: 8px 16px
    border-radius: 40px
.language-flag
  align-self: center
  width: 20px
  height: 16px
  border-radius: 8px
  object-fit: cover
</style>
