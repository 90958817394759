<template>
  <div class="availability-item">
    <div class="content-title">
      {{ item.name }}
    </div>

    <div class="body">
      <template v-if="!item.disabled">
        <div class="availability-hours">
          <div v-for="(hour, index) in item.hours" :key="index">
          {{ hour.start }} - {{ hour.end }}
          </div>
        </div>
      </template>

      <template v-else-if="item.disabled">
        {{ $translations.profile['not-available'] }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailabilityItem',
  props: {
    item: {
      name: String,
      hours: Array,
      disabled: Boolean
    }
  }
}
</script>
<style lang="sass" scoped>
.availability-item
  padding: 16px
  display: flex
  justify-content: space-between
  border-bottom: 2px solid var(--gray-10)
.availability-hours
  display: flex
  flex-direction: column
  gap: 8px

</style>
