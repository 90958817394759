<template>
  <div v-if="specialist">
    <div class="page-segment">
      <div class="page-segment-content">
        <div class="heading-small margin-bottom" v-text="$translations.profile['personal-info']" />
        <PersonalInfo :specialist="specialist" />
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <Heading
          :text="$translations.profile.sections['about-me']"
          :action="aboutMeAction"
        />
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <Heading
          :text="$translations.profile.sections['therapy-style']"
          :action="therapyStyleAction"
        />
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <Language :languages="specialist.languages" />
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <ProfileSecurity :email="specialist.user.email" />
      </div>
    </div>
  </div>
</template>

<script>
import { Heading } from '@seliaco/red-panda'
import Language from '@/views/profile/components/general/Language'
import PersonalInfo from '@/views/profile/components/general/PersonalInfo'
import ProfileSecurity from '@/views/profile/components/general/ProfileSecurity'

export default {
  name: 'GeneralSection',
  components: {
    Heading,
    Language,
    ProfileSecurity,
    PersonalInfo
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      aboutMeAction: {
        icon: 'icon-arrow-big-outline-right',
        callback: () => this.$router.push({ name: 'AboutMe' })
      },
      therapyStyleAction: {
        icon: 'icon-arrow-big-outline-right',
        callback: () => this.$router.push({ name: 'TherapyStyle' })
      }
    }
  }
}
</script>
