<template>
  <div>
    <template v-if="loading">
      <Skeleton
        width="20%"
        height="22px"
        borderRadius="8px"
        class="margin-bottom"
      />
      <div class="availability-skeleton">
        <Skeleton
          width="50%"
          height="35px"
          borderRadius="8px"
          class="margin-bottom"
        />
        <Skeleton
          width="50%"
          height="35px"
          borderRadius="8px"
          class="margin-bottom"
        />
      </div>
      <Skeleton
        width="20%"
        height="22px"
        borderRadius="8px"
        class="margin-bottom"
      />
      <Skeleton
        width="100%"
        height="40px"
        borderRadius="8px"
        class="margin-bottom-xs"
      />
    </template>
    <!-- availability -->
    <template v-else>
      <!-- Services -->
      <Heading :text="$translations.profile.schedule" :action="action" />

      <!-- Modality -->
      <ModalitySelector v-model="modality" />

      <!-- infobanner -->
      <InfoBanner
        v-bind="infoBannerData"
        @action="pushServicesList"
        v-if="modality === 'FACE_TO_FACE' && !inPersonIsActive"
      />
      <template v-if="loadingStore">
        <Skeleton
          class="margin-bottom-xs"
          width="30%"
          height="25px"
          borderRadius="16px"
        />
        <Skeleton
          class="margin-bottom-lg"
          width="80%"
          height="40px"
          borderRadius="16px"
        />
        <Skeleton
          class="margin-bottom-xs"
          width="30%"
          height="25px"
          borderRadius="16px"
        />
        <Skeleton
          width="100%"
          height="54px"
          borderRadius="1rem"
          class="margin-bottom-xs"
          v-for="index in 7"
          :key="index"
        />
      </template>

      <template v-if="!loadingStore && calendarValidation">
        <!-- time -->
        <TimeInAdvance :time="timeInAdvance" v-if="calendarValidation" />

        <!-- weekAvalability -->
        <div
          class="content-title margin-bottom"
          v-if="availability.length"
          v-text="$translations.profile.schedule"
        />
        <AvailabilityItem
          v-for="(item, index) in availability"
          :key="index"
          :item="item"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { Heading, InfoBanner, Skeleton, getClientTimezone } from '@seliaco/red-panda'
import AvailabilityItem from '@/views/profile/components/schedule/AvailabilityItem'
import TimeInAdvance from '@/views/profile/components/schedule/TimeInAdvance'
import ModalitySelector from '@/components/fields/ModalitySelector'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Availability',
  components: {
    AvailabilityItem,
    Skeleton,
    Heading,
    TimeInAdvance,
    ModalitySelector,
    InfoBanner
  },
  props: {
    services: Array
  },
  data () {
    return {
      selectedItem: false,
      availability: [],
      rawAvailability: null,
      days: {
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
        0: 'sunday'
      },
      timeInAdvance: null,
      loading: true,
      modality: 'ONLINE',
      action: {
        text: this.$translations.general.edit,
        callback: () => this.goAvailability()
      },
      infoBannerData: {
        icon: 'icon-information-off',
        iconColor: 'yellow',
        background: 'gray-10',
        text: this.$translations.profile.service['face-to-face-disactive'],
        textAction: this.$translations.profile.service.edit
      },
      inPersonIsActive: true,
      timeZone: getClientTimezone()
    }
  },
  created () {
    this.$store.dispatch('availability/fetch')
    this.$store.watch(
      () => this.availabilityStore,
      () => {
        this.get()
      }
    )

    this.modality = this.modalityStore === 'VIRTUAL' ? 'ONLINE' : 'FACE_TO_FACE'
  },
  methods: {
    get () {
      const data = this.availabilityStore.weekAvailability
      this.rawAvailability = data
      this.availability = []

      if (data) {
        Object.keys(data).forEach((key, value) => {
          let hours = {
            start: '00:00',
            end: '00:00'
          }
          let disabled = false

          const hourGroup = data[key].map((i) => ({
            start: this.$moment(i.start, 'HH:mm')
              .tz(this.timeZone)
              .format('hh:mm a'),
            end: this.$moment(i.end, 'HH:mm')
              .tz(this.timeZone)
              .format('hh:mm a')
          }))

          if (data[key].length === 1) {
            hours = data[key][0]
            disabled = hours.start === '00:00' && hours.end === '00:00'
          }

          this.availability.push({
            name: this.$translations.locale.days[this.days[key]],
            disabled,
            hours: [...hourGroup]
          })
        })

        if (this.modalityStore === 'IN_PERSON') {
          this.inPersonIsActive = Object.keys(data).length > 0
        }
      }

      this.timeInAdvance = this.availabilityStore.timeInAdvance
      this.loading = false
    },
    goAvailability () {
      this.$router.push({ name: 'Availability', query: { back: this.$route.fullPath } })
    },
    handlerModality (val) {
      this.setModality({ modality: val })
      this.modality = val
    },
    pushServicesList () {
      this.$router.push({ name: 'ServicesList' })
    },
    ...mapActions({
      setModality: 'availability/setModality'
    })
  },
  computed: {
    ...mapGetters({
      userTimezone: 'userTimezone',
      availabilityStore: 'availability/get',
      modalityStore: 'availability/modality',
      loadingStore: 'availability/loading',
      availabilityDefault: 'availability/availabilityDefault'
    }),
    calendarValidation () {
      if (this.availabilityStore?.weekAvailability) {
        return Object.keys(this.availabilityStore.weekAvailability).length > 0
      }

      return false
    }
  },
  watch: {
    modality: {
      handler: 'handlerModality'
    }
  }
}
</script>
<style lang="sass" scoped>
.availability-skeleton
  display: flex
  gap: 8px
  width: 50%
</style>
